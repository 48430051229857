.about-me {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.small-about-me {
  margin-top: 400px;
  display: flex;
  flex-direction: column;
}

.about-me-transition {
  transition-duration: 0.5s;
}

#about-me-title {
  font-size: 30px;
}

#about-me-break {
  width: 100px;
}

.between-break {
  width: 70%;
}

#about-me-content {
  width: 70%;
  display: flex;
  align-self: center;
  line-height: 35px;
  padding: 40px;
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  #about-me-title {
    font-size: 20px;
  }
  #about-me-content {
    line-height: 15px;
    padding: 0px;
    font-size: 12px;
  }
  .small-about-me {
    margin-top: 200px;
  }
}
