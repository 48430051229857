.contact-me {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-me-title {
  font-size: 30px;
  margin-top: 75px;
}

#contact-me-break {
  width: 100px;
}

#contact-me-intro {
  width: 70%;
}

#contact-form {
  margin-top: 20px;
  margin-bottom: 100px;
}

#contact-me-message-input {
  height: 90px;
  width: 300px;
}

@media only screen and (max-width: 600px) {
  #contact-me-title {
    font-size: 20px;
    margin-top: 45px;
  }
  #contact-me-intro {
    font-size: 12px;
    width: 80%;
  }
  #contact-form {
    width: 75%;
  }
  .ant-form-item-required {
    font-size: 10px;
    height: auto;
  }
  .ant-col,
  .ant-form-item-label {
    height: auto;
  }
  #contact-me-message-input {
    height: 75px;
  }
}
