@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

hr {
  border: none;
  height: 1px;
  background-color: lightgray;
}

#footer-box {
  width: 90%;
  background-color: black;
  height: 100px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.header-box {
  background-color: black;
  width: 90%;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.small-header-box {
  position: fixed;
  background-color: black;
  width: 90%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-contents {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-links {
  display: flex;
  flex-direction: row;
  margin: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.header-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
}

.header-link:hover {
  color: lightgray;
}

.header-transition {
  transition-duration: 0.3s;
  transition-delay: 0ms;
}

.name {
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 85px;
  margin-left: 120px;
  margin-bottom: 0;
  top: 0;
}

.small-name {
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 40px;
  margin-left: 50px;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  top: 0px;
}

.header-description {
  font-size: 30px;
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  margin-left: 130px;
  margin-top: 0;
}

.small-header-description {
  display: none;
}

@media only screen and (max-width: 1030px) {
  .header-box {
    flex-direction: column-reverse;
    height: 300px;
  }
  .name,
  .header-description {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .header-box {
    width: 85%;
    height: 230px;
  }
  .small-header-box {
    height: 80px;
    justify-content: space-around;
  }
  .name {
    font-size: 45px;
  }
  .header-description {
    font-size: 25px;
  }
  .header-link {
    font-size: 12px;
  }
  .small-header-links {
    margin-top: 55px;
  }
}

.about-me {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.small-about-me {
  margin-top: 400px;
  display: flex;
  flex-direction: column;
}

.about-me-transition {
  transition-duration: 0.5s;
}

#about-me-title {
  font-size: 30px;
}

#about-me-break {
  width: 100px;
}

.between-break {
  width: 70%;
}

#about-me-content {
  width: 70%;
  display: flex;
  align-self: center;
  line-height: 35px;
  padding: 40px;
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  #about-me-title {
    font-size: 20px;
  }
  #about-me-content {
    line-height: 15px;
    padding: 0px;
    font-size: 12px;
  }
  .small-about-me {
    margin-top: 200px;
  }
}

#projects-break {
  width: 100px;
}

#projects-title {
  font-size: 30px;
  margin-top: 75px;
}

#projects-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project {
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 150px;
}

.project-2 {
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 150px;
}

.project-image {
  width: 300px;
  border: 1px solid lightgrey;
  margin-left: 130px;
  padding: 10px;
}

.project-2-image {
  width: 300px;
  border: 1px solid lightgrey;
  margin-right: 130px;
  padding: 10px;
}

.project-3-image {
  height: 300px;
  width: auto;
}

.project-text {
  line-height: 25px;
  font-size: 18px;
}

.project-break {
  width: 50%;
  color: lightgray;
  margin: 30px;
}

.github-link,
.deployed-site-link {
  text-decoration: underline;
  color: grey;
  font-size: 15px;
}

.github-link:hover,
.deployed-site-link:hover {
  color: lightgray;
}

.github-link {
  margin-right: 90px;
}

.between-break {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  #projects-title {
    font-size: 20px;
    margin-top: 45px;
  }
  .project {
    flex-direction: column-reverse;
    margin-left: 0;
    width: 80%;
    margin-top: 10px;
  }
  .project-2 {
    margin-right: 0;
    flex-direction: column;
    width: 80%;
  }
  .project-text {
    line-height: 15px;
    padding: 0px;
    font-size: 12px;
  }
  .project-image,
  .project-3-image {
    margin-left: 0;
  }
  .project-2-image {
    margin-right: 0;
  }
  .github-link,
  .deployed-site-link {
    font-size: 10px;
  }
  .github-link {
    margin-right: 30px;
  }
  .project-image,
  .project-2-image,
  .project-3-image {
    margin-bottom: 10px;
    width: 150px;
    height: auto;
  }
}

.contact-me {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-me-title {
  font-size: 30px;
  margin-top: 75px;
}

#contact-me-break {
  width: 100px;
}

#contact-me-intro {
  width: 70%;
}

#contact-form {
  margin-top: 20px;
  margin-bottom: 100px;
}

#contact-me-message-input {
  height: 90px;
  width: 300px;
}

@media only screen and (max-width: 600px) {
  #contact-me-title {
    font-size: 20px;
    margin-top: 45px;
  }
  #contact-me-intro {
    font-size: 12px;
    width: 80%;
  }
  #contact-form {
    width: 75%;
  }
  .ant-form-item-required {
    font-size: 10px;
    height: auto;
  }
  .ant-col,
  .ant-form-item-label {
    height: auto;
  }
  #contact-me-message-input {
    height: 75px;
  }
}

