#projects-break {
  width: 100px;
}

#projects-title {
  font-size: 30px;
  margin-top: 75px;
}

#projects-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project {
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 150px;
}

.project-2 {
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 150px;
}

.project-image {
  width: 300px;
  border: 1px solid lightgrey;
  margin-left: 130px;
  padding: 10px;
}

.project-2-image {
  width: 300px;
  border: 1px solid lightgrey;
  margin-right: 130px;
  padding: 10px;
}

.project-3-image {
  height: 300px;
  width: auto;
}

.project-text {
  line-height: 25px;
  font-size: 18px;
}

.project-break {
  width: 50%;
  color: lightgray;
  margin: 30px;
}

.github-link,
.deployed-site-link {
  text-decoration: underline;
  color: grey;
  font-size: 15px;
}

.github-link:hover,
.deployed-site-link:hover {
  color: lightgray;
}

.github-link {
  margin-right: 90px;
}

.between-break {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  #projects-title {
    font-size: 20px;
    margin-top: 45px;
  }
  .project {
    flex-direction: column-reverse;
    margin-left: 0;
    width: 80%;
    margin-top: 10px;
  }
  .project-2 {
    margin-right: 0;
    flex-direction: column;
    width: 80%;
  }
  .project-text {
    line-height: 15px;
    padding: 0px;
    font-size: 12px;
  }
  .project-image,
  .project-3-image {
    margin-left: 0;
  }
  .project-2-image {
    margin-right: 0;
  }
  .github-link,
  .deployed-site-link {
    font-size: 10px;
  }
  .github-link {
    margin-right: 30px;
  }
  .project-image,
  .project-2-image,
  .project-3-image {
    margin-bottom: 10px;
    width: 150px;
    height: auto;
  }
}
