.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.header-box {
  background-color: black;
  width: 90%;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.small-header-box {
  position: fixed;
  background-color: black;
  width: 90%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-contents {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-links {
  display: flex;
  flex-direction: row;
  margin: 20px;
  height: fit-content;
}

.header-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
}

.header-link:hover {
  color: lightgray;
}

.header-transition {
  transition-duration: 0.3s;
  transition-delay: 0ms;
}

.name {
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 85px;
  margin-left: 120px;
  margin-bottom: 0;
  top: 0;
}

.small-name {
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 40px;
  margin-left: 50px;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  top: 0px;
}

.header-description {
  font-size: 30px;
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  margin-left: 130px;
  margin-top: 0;
}

.small-header-description {
  display: none;
}

@media only screen and (max-width: 1030px) {
  .header-box {
    flex-direction: column-reverse;
    height: 300px;
  }
  .name,
  .header-description {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .header-box {
    width: 85%;
    height: 230px;
  }
  .small-header-box {
    height: 80px;
    justify-content: space-around;
  }
  .name {
    font-size: 45px;
  }
  .header-description {
    font-size: 25px;
  }
  .header-link {
    font-size: 12px;
  }
  .small-header-links {
    margin-top: 55px;
  }
}
